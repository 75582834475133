import React from 'react'
import CareerLayout from '../../components/careerLayout'

export default function SalesRepresentative () {
  return (
    <CareerLayout title='Sales Representative' location='Remote'>
      <h2>Overview</h2>
      <p>Trexity is a start up with big ambitions and we’re looking for a Sales Representative who has an equally enthusiastic mindset. We need a results-driven hire with outstanding interpersonal skills to actively seek out and engage with our merchant prospects.</p>
      <p className='mb'>You will generate new business leads and help navigate them through our sales funnel. This is a self directed role, reporting to our sales lead. This is a full time role and can be remote.</p>

      <h2>Responsibilities</h2>
      <ul className='trx-bullet mb'>
        <li>Contact prospective customers by telephone, email and in-person (Note: in-person will be post-pandemic)</li>
        <li>Use database and CRM to track progress with new customers</li>
        <li>Use product knowledge to showcase the solutions that our company can offer to customers</li>
        <li>Provides feedback to help build on our internal operations</li>
      </ul>

      <h2>Experience and skills</h2>
      <ul className='trx-bullet mb'>
        <li>1-3 years of sales experience in a B2B fast-paced technology/software environment</li>
        <li>Experience with cold calling and emailing</li>
        <li>Excellent relationship, communication, and influence skills</li>
        <li>The ability to work in a self-directed manner navigating through a fast-paced and ambiguous environment that is steadily evolving</li>
        <li>High energy and strong work ethic driving towards meeting or exceeding the company’s goals</li>
        <li>A passion for talking to people, curiosity for understanding how we can help businesses and showing them how to create new streams of getting packages to their customers</li>
        <li>Ability to learn and collaborate with a team remotely</li>
        <li>Experience working at a startup is a plus</li>
        <li>Understanding of retail and e-commerce landscape is a plus</li>
      </ul>

      <h2>Benefits</h2>
      <ul className='trx-bullet mb'>
        <li>Equal opportunity employer</li>
        <li>Full health benefits from day one</li>
        <li>3 weeks vacation</li>
        <li>Employee Share Option Plan</li>
        <li>Competitive compensation (base + commission)</li>
      </ul>
    </CareerLayout>
  )
}
