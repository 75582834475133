import React from 'react'
import PropTypes from 'prop-types'
import Config from '../config'

const MerchantSignUpButton = ({
  style,
  text = 'Become a Merchant'
}) => {
  return (
    <a
      className='button-link button-link-merchant'
      href={`${Config.MERCHANT_PORTAL_URL}/signup`}
      onClick={() => {
        if (typeof window !== 'undefined' && typeof window.fbq !== 'undefined' && typeof window.ga !== 'undefined') {
          if (Config.ENABLE_SOCIAL_TRACKING) {
            window.fbq('track', 'InitiateRegistration')
            // window.ga('send', 'event', 'merchant', 'signup', 'intent')
            // eslint-disable-next-line quote-props
            // window.dataLayer.push({ 'event': 'merchant-signup-intent' })
          }
        }
      }}
      style={style}
    >{text}</a>
  )
}

MerchantSignUpButton.propTypes = {
  style: PropTypes.object,
  text: PropTypes.string
}

export default MerchantSignUpButton
