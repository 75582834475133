import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { ClearButton } from '@trexity/common-client/lib/components'
import Config from '../config'
import MerchantSignUpButton from '../components/merchantSignUpButton'

const Layout = ({
  children,
  type,
  location
}) => {
  const [active, setActive] = useState(false)
  const [scrolled, setScrolled] = useState(false)
  const [scrolledBottom, setScrolledBottom] = useState(false)
  const [companyMenu, setCompanyMenu] = useState(false)
  const [getStartedMenu, setGetStartedMenu] = useState(false)
  const [citiesMenu, setCitiesMenu] = useState(false)

  const onMenuToggle = React.useCallback((toggle) => {
    if (toggle) setCompanyMenu(false)
    setCitiesMenu(toggle)
  })

  // change state on scroll
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10
      const isScrolledBottom = (window.scrollY + window.innerHeight >= document.body.scrollHeight)

      if (isScrolled !== scrolled) {
        setScrolled(!scrolled)
      }

      setScrolledBottom(isScrolledBottom)
    }

    if (typeof document !== 'undefined') {
      document.addEventListener('scroll', handleScroll, { passive: true })
    }

    return () => {
      if (typeof document !== 'undefined') {
        // clean up the event handler when the component unmounts
        document.removeEventListener('scroll', handleScroll)
      }
    }
  }, [scrolled])

  return (
    <div id='top' className={`page ${type}`}>
      <Helmet>
        <title>Trexity</title>
        {/* Gibson Regular/Semibold
        <link rel='stylesheet' href='https://use.typekit.net/dtd2ale.css' /> */}
        {/* Gibson Book/Semibold */}
        <link rel="stylesheet" href="https://use.typekit.net/doa2tds.css" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />

        {/* Google Maps API */}
        <meta name="google-site-verification" content="c2vkcvBIc1eJgVtclBtjglN1sKahSYsggp8mB5MAXq8" />

        {/* Essential META Tags */}
        <meta property="og:title" content="Delight your local customers with Trexity same-day delivery." />
        <meta property="og:description" content="With a flexible, scalable, and cost-effective driver fleet at your command, the new standard of door-to-door service is yours." />
        <meta property="og:image" content={`${Config.SITE_URL}/img/trexity-og.jpg`} />
        <meta property="og:url" content={Config.SITE_URL} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="facebook-domain-verification" content="q3eoff0xmlthg9ily6p6k8tt0yrbny" />

        {/* Non-Essential, But Recommended */}
        <meta property="og:site_name" content="Delight your local customers with Trexity same-day delivery." />
        <meta name="twitter:image:alt" content="Person with laptop and delivery confirmation" />

        {/* Non-Essential, But Required for Analytics
        <meta property="fb:app_id" content="your_app_id" />
        <meta name="twitter:site" content="@website-username" /> */}
      </Helmet>

      {active ? (
        <div className='nav-overlay' onClick={() => { setActive(false) }}></div>
      ) : null}

      <header id='main-nav' className={scrolled && !active ? 'active' : null}>
        <section>
          <div className={active ? 'active' : ''}>
            <Link to='/' className='logo'>
              <img src='/img/trexity-logo-blue.png' alt='Trexity' />
            </Link>
            <ClearButton onClick={() => { setActive(!active) }} style={{ padding: 0 }} className='menu-button'>
              {active ? <i className="material-icons">close</i> : <i className="material-icons">menu</i>}
            </ClearButton>
          </div>
          <nav className={active ? 'active' : ''}>
            <div className='row space-between'>
              <ul className='menu row'>
                <li><Link to='/merchants/' activeClassName="active">Merchants</Link></li>
                <li><Link to='/drivers/' activeClassName="active">Drivers</Link></li>
                {/* <li className='sub-menu'><CitiesMenu onToggleExpand={onCitiesMenuToggle} expanded={citiesMenu} /></li> */}
                <li className='sub-menu'>
                  <a
                    onClick={() => {
                      setCompanyMenu(!companyMenu)
                      setGetStartedMenu(false)
                    }}
                  >
                    Company <i className='material-icons'>{companyMenu ? 'expand_less' : 'expand_more'}</i>
                  </a>
                  {companyMenu ? (
                    <div>
                      <div className='sub-menu-overlay' onClick={() => { setCompanyMenu(false) }}></div>
                      <ul className='menu'>
                        <li><Link to='/merchant-profiles/' activeClassName="active">Merchant profiles</Link></li>
                        <li><Link to='/partners/' activeClassName="active">Partners</Link></li>
                        <li><Link to='/careers/' activeClassName="active">Careers</Link></li>
                        <li><Link to='/about/' activeClassName="active">About</Link></li>
                        <li><Link to='/press/' activeClassName="active">Press</Link></li>
                        <li><Link to='/contact/' activeClassName="active">Contact</Link></li>
                      </ul>
                    </div>
                  ) : null}
                </li>
              </ul>
              <ul className='menu row'>
                <li><Link to='/pricing/' activeClassName="active">Pricing</Link></li>
                <li><Link to={Config.ZONES_PAGE_REDIRECT_URL} target='_blank' activeClassName="active">Zones</Link></li>
                <li><a href={`${Config.MERCHANT_PORTAL_URL}/login`} className="log-in">Log in</a></li>
                <li className='sub-menu'>
                  <a
                    className='button-link button-link-brand'
                    onClick={() => {
                      setGetStartedMenu(!getStartedMenu)
                      setCompanyMenu(false)
                    }}
                  >GET STARTED</a>
                  {getStartedMenu ? (
                    <div className='right'>
                      <div className='sub-menu-overlay' onClick={() => { setGetStartedMenu(false) }}></div>
                      <ul className='menu'>
                        <li>
                          <a
                            href={`${Config.MERCHANT_PORTAL_URL}/signup`}
                            className='merchant-get-started'
                            onClick={() => {
                              if (typeof window !== 'undefined' && typeof window.fbq !== 'undefined' && typeof window.ga !== 'undefined') {
                                if (Config.ENABLE_SOCIAL_TRACKING) {
                                  window.fbq('track', 'InitiateRegistration')
                                }
                              }
                            }}
                          >
                            <i className="material-icons">store</i>
                            <div>
                              <h4>Merchant</h4>
                              I have packages to deliver
                            </div>
                          </a>
                        </li>
                        <li style={{ marginTop: 15 }}>
                          {location === '/drivers/' ? (
                            <a
                              onClick={() => {
                                setGetStartedMenu(false)
                                setActive(false)
                              }}
                              className='driver-get-started'
                            >
                              <i className="material-icons">drive_eta</i>
                              <div>
                                <h4>Driver</h4>
                                I want to deliver packages
                              </div>
                            </a>
                          ) : (
                            <Link to='/drivers/' className='driver-get-started'>
                              <i className="material-icons">drive_eta</i>
                              <div>
                                <h4>Driver</h4>
                                I want to deliver packages
                              </div>
                            </Link>
                          )}
                        </li>
                      </ul>
                    </div>
                  ) : null}
                </li>
              </ul>
            </div>
          </nav>
        </section>
      </header>

      <div id="body" className={`${type} ${scrolledBottom ? 'scrolled-bottom' : ''}`}>
        {children}
      </div>

      <footer id="main-footer">
        <section className='no-margin'>
          <div className="row equal">
            <div className="col">
              <div className="inner">
                <h5>Information</h5>
                <ul>
                  <li><Link to='/merchants/' activeClassName="active">Merchants</Link></li>
                  <li><Link to='/pricing/' activeClassName="active">Pricing</Link></li>
                  <li><Link to='/partners/' activeClassName="active">Partners</Link></li>
                  <li><Link to='/drivers/' activeClassName="active">Drivers</Link></li>
                  <li><Link to='/covid19/' activeClassName="active">Covid-19</Link></li>
                </ul>
              </div>
            </div>
            <div className="col">
              <div className="inner">
                <h5>Trexity</h5>
                <ul>
                  <li><Link to={Config.ZONES_PAGE_REDIRECT_URL} target='_blank' activeClassName="active">Zones</Link></li>
                  <li><Link to='/about/' activeClassName="active">About</Link></li>
                  <li><Link to='/press/' activeClassName="active">Press</Link></li>
                  <li><Link to='/careers/' activeClassName="active">Careers</Link></li>
                  <li><Link to='/contact/' activeClassName="active">Contact</Link></li>
                </ul>
              </div>
            </div>
            <div className="col">
              <div className="inner">
                <h5>Support</h5>
                <ul>
                  <li><Link to='/developers/' activeClassName="active">Developers</Link></li>
                  <li><a target='blank' href='https://trexity.zendesk.com/hc/en-us'>Knowledge Center</a></li>
                  <li><Link to='/privacy-policy/' activeClassName="active">Privacy Policy</Link></li>
                  <li><Link to='/terms-of-service/' activeClassName="active">Terms of Service</Link></li>
                </ul>
              </div>
            </div>
            <div className="col">
              <div className="inner">
                <h5>Trexity Driver App</h5>
                <p>Your personal delivery assistant. Download to start driving.</p>
                <div className="app-store">
                  <a href="https://apps.apple.com/ca/app/trexity-driver/id1479475710"><img src="/img/store-apple.png" alt="Download on the App Store" /></a>
                  <a href="https://play.google.com/store/apps/details?id=com.trexity.drivermobile"><img src="/img/store-google.png" alt="Get it on Google Play" /></a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </footer>

      <div className="secondary-footer">
        <section>
          <div className="inner">
            <ul className="social">
              <li><a href="https://www.instagram.com/trexityapp/" target="_blank" rel="noreferrer"><img src="/img/social-instagram.png" alt="Instagram" /></a></li>
              <li><a href="https://www.linkedin.com/company/trexity/about/" target="_blank" rel="noreferrer"><img src="/img/social-linkedin.png" alt="Linkedin" /></a></li>
              <li><a href="https://www.youtube.com/channel/UCgPSAqwQf-VjoxEY782_IYA" target="_blank" rel="noreferrer"><img src="/img/social-youtube.png" alt="Youtube" /></a></li>
            </ul>
            <a href='#top' className='back-to-top'>Back to top <i className="material-icons">north</i></a>
          </div>
        </section>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  location: PropTypes.string
}

// const CitiesMenu = (props) => {
//   const { onToggleExpand, expanded = false } = props

//   const query = useStaticQuery(graphql`
//     query CitiesQuery {
//       allCitiesJson(sort: {order: ASC, fields: [order]}) {
//         edges {
//           node {
//             id
//             name
//             slug
//           }
//         }
//       }
//     }
//   `)

//   const call = (fn) => typeof fn === 'function' ? fn() : fn
//   const renderWhen = (testExpr, expr) => call(testExpr) ? expr : null

//   const listItems = query.allCitiesJson.edges.map(({ node }) => {
//     return (<li key={node.id}><Link to={`/city/${node.slug}`} activeClassName="active">{node.name}</Link></li>)
//   })

//   const subMenu = (
//     <div>
//       <div className='sub-menu-overlay' onClick={() => { onToggleExpand(false) }}></div>
//       <ul className='menu'>{listItems}</ul>
//     </div>
//   )

//   return (
//     <React.Fragment>
//       <a onClick={() => onToggleExpand(!expanded)}>
//         City <i className='material-icons'>{expanded ? 'expand_less' : 'expand_more'}</i>
//       </a>
//       {renderWhen(expanded, subMenu)}
//     </React.Fragment>
//   )
// }

// CitiesMenu.propTypes = {
//   // (expanded:boolean) => void
//   onToggleExpand: PropTypes.func.isRequired,
//   expanded: PropTypes.bool
// }

export default Layout
