import React from 'react'
import PropTypes from 'prop-types'
import Layout from './layout'

const CareerLayout = ({ title, location, children }) => {
  return (
    <Layout>
      <section className='m-mb-0'>
        <div className='row justify-center'>
          <div className='col w75'>
            <div className="well inner">
              <h1>{title}</h1>
              <p className='mb lede muted'>Location: {location}</p>

              <div style={{ borderTop: '1px solid #e5e5e5', paddingTop: 30 }}>
                {children}
              </div>

              <div style={{ borderTop: '1px solid #e5e5e5', paddingTop: 30 }}>
                <p className='lede mb'>Send your resume and explain why you want to work with Trexity to <a href={`mailto:careers@trexity.com?subject=${title}`}>careers@trexity.com</a>.</p>
                <a href={`mailto:careers@trexity.com?subject=${title}`} className='button-link button-link-brand'>Apply now</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

CareerLayout.propTypes = {
  title: PropTypes.string,
  location: PropTypes.string,
  children: PropTypes.node
}

export default CareerLayout
